import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/chemicalapp.png'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/weather image.jpg'




const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Chemical Inventory System',
    github: 'https://github.com/DeMario2011/the-a-team',
    demo: 'https://react-chemical-front.vercel.app/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Charts templates & infographics in Figma',
    github: 'https://github.com',
    demo: 'https://dribbble.com/shots/16580766-Orion-UI-kit-Charts-templates-infographics-in-Figma'
  },
  {
    id: 3,
    image: IMG3,
    title: 'weather',
    github: '',
    demo: 'https://weatherapp-31fd2.firebaseapp.com/'
  },

]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio